.app-subnav {
  // Since the back to top link is outside the flow of the document we need to create a space for it.
  // This number is magic and was determined by manually judging the visual spacing.
  margin-bottom: 100px;
  padding: govuk-spacing(6) govuk-spacing(3) 0 0;
  @include govuk-font(16);
}

.app-subnav__section {
  margin: 0 0 govuk-spacing(4);
  padding: 0;
  list-style-type: none;
}

.app-subnav__link {
  padding: 2px 0;
}

.app-subnav__section-item {
  margin-bottom: govuk-spacing(1);
  padding-top: govuk-spacing(1);
  padding-bottom: govuk-spacing(1);
}

.app-subnav__section-item--current {
  $_current-indicator-width: 4px;
  margin-left: -(govuk-spacing(2) + $_current-indicator-width);
  padding-left: govuk-spacing(2);
  border-left: $_current-indicator-width solid govuk-colour("blue");
  background-color: govuk-colour("white");
}

.app-subnav__section-item--current .app-subnav__link {
  font-weight: bold;
}

.app-subnav__section--nested {
  margin-top: govuk-spacing(2);
  margin-bottom: 0;
  padding-left: govuk-spacing(4);
}

.app-subnav__section--nested .app-subnav__section-item::before {
  content: "—";
  margin-left: -(govuk-spacing(4));
  color: govuk-colour("dark-grey");
}

.app-subnav__section--nested .app-subnav__link {
  padding-left: 0;
  font-weight: normal;
}

.app-subnav__theme {
  @include govuk-font-size($size: 19);
  @include govuk-typography-weight-regular;
  margin: 0;
  padding: govuk-spacing(2) govuk-spacing(3) govuk-spacing(2) 0;
  color: govuk-colour("dark-grey");
}
