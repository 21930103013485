// Example styles
.app-example-wrapper {
  @include govuk-responsive-margin(6, "top");
  @include govuk-responsive-margin(6, "bottom");
  border: 1px solid $govuk-border-colour;
  border-top: 0;
}

.app-example {
  @include govuk-font-size($size: 16);
  position: relative;
  border-top: 1px solid $govuk-border-colour;
  // Add a 'checkerboard' background
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAJUlEQVQoU2P88ePHfwY0wMHBwYguxjgUFKI7GsTH5m4M3w1ChQAZTSeO0/AZpgAAAABJRU5ErkJggg==")
    repeat;
}

.app-example--tabs {
  @include govuk-responsive-margin(0, "bottom");
}

.app-example__toolbar {
  padding: 10px;
  border-bottom: 1px solid $govuk-border-colour;
  background: $govuk-body-background-colour;
}

.app-example__frame {
  display: block;
  width: 100%;
  max-width: 100%;
  border: 0;
  border-right: 1px solid $govuk-border-colour;
  background: govuk-colour("white");
}

// Default size
.app-example__frame,
.app-example__frame--xs {
  height: 150px;
}

.app-example__frame--s {
  height: 250px;
}

.app-example__frame--m {
  height: 350px;
}

.app-example__frame--l {
  height: 450px;
}

.app-example__frame--xl {
  height: 550px;
}

.app-example__frame--resizable {
  min-width: 230px;
  min-height: govuk-spacing(6) * 2;
  overflow: auto;
  transform: translate3d(0, 0, 0);
  @include govuk-media-query($from: desktop) {
    resize: both;
  }
}

.app-example__code {
  position: relative;
}
