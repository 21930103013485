.app-contact-panel {
  @include govuk-responsive-margin(9, "top");
  padding-top: govuk-spacing(3);
  border-top: 2px solid govuk-colour("blue");
}

.app-contact-panel__heading,
.app-contact-panel__body,
.app-contact-panel__link {
  max-width: 100%;
}

.app-contact-panel__heading {
  @extend %govuk-heading-m;
  padding-top: 10px;

  &:first-child {
    padding-top: 0;
  }
}

.app-contact-panel__body:last-child {
  margin-bottom: 0;
}
