$navigation-height: 50px;

.app-navigation {
  border-bottom: 1px solid $govuk-border-colour;
  background-color: $app-light-grey;
}

.app-navigation__list {
  margin: 0;
  padding: 0;
  list-style: none;

  @include govuk-media-query($from: tablet) {
    position: relative;

    // Offset gutter by tablet list-item padding
    left: $govuk-gutter-half * -1;
    width: calc(100% - $govuk-gutter);
    min-height: $navigation-height;
  }
}

.app-navigation__list-item {
  position: relative;

  @include govuk-media-query($from: tablet) {
    box-sizing: border-box;
    height: govuk-px-to-rem($navigation-height);
    padding: 0 govuk-spacing(3);
    float: left;
    line-height: $navigation-height;
  }
}

.app-navigation__list-item--current {
  @include govuk-media-query($from: tablet) {
    border-bottom: 4px solid govuk-colour("blue");
  }
}

.app-navigation__link,
.app-navigation__button {
  margin: govuk-spacing(3) 0;
  padding: 0;
  @include govuk-typography-weight-bold; // Override .govuk-link weight
  // Type scale doesn't provide 19 across all screen sizes on mobile so we
  // override it here
  font-size: govuk-px-to-rem(19px);

  // Expand the touch area of the link to the full menu width
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.app-navigation__link:not([hidden]) {
  display: inline-block;

  @include govuk-media-query($from: tablet) {
    display: inline;
  }
}

.app-navigation__button {
  @include govuk-link-common;
  @include govuk-link-style-no-underline;
  @include govuk-link-style-no-visited-state;
  border: 0;
  color: $govuk-link-colour;
  background-color: transparent;
}

.app-navigation__subnav {
  margin: 0 govuk-spacing(-3);
  padding: govuk-spacing(2) 0;
  background-color: govuk-colour("white");
  list-style: none;
}

.govuk-frontend-supported .app-navigation__subnav--active {
  display: block;
}

.app-navigation__subnav,
.govuk-frontend-supported .app-navigation__subnav--active {
  @include govuk-media-query($from: tablet) {
    display: none;
  }
}

.app-navigation__subnav-item {
  display: block;
  position: relative;
  padding: govuk-spacing(3);
}

.app-navigation__subnav-item--current {
  $_current-indicator-width: 4px;
  padding-left: govuk-spacing(4) - $_current-indicator-width;
  border-left: $_current-indicator-width solid govuk-colour("blue");
}

.app-navigation__theme {
  @include govuk-typography-common;
  position: relative; // this is to get around the artificial click area generated by the :after of the parent button
  margin: 0;
  padding: govuk-spacing(4) govuk-spacing(3) govuk-spacing(1);
  color: govuk-colour("dark-grey");
  // Font is defined as a hard 19px so
  // it does not re-size on mobile viewport
  font-size: 19px;
  font-size: govuk-px-to-rem(19px);
  font-weight: normal;
}

// overwrite govuk-header__menu-button's position and it displaying on tablet
.app-navigation__menu-button {
  top: auto;
  right: govuk-spacing(1);
  bottom: govuk-spacing(5);
}
