.app-page-navigation {
  margin-top: 0;
  margin-bottom: govuk-spacing(6);
  padding-left: govuk-spacing(4);
  list-style: none;
}

.app-page-navigation__item {
  @include govuk-typography-common;
  margin-bottom: govuk-spacing(2);

  @include govuk-media-query($from: tablet) {
    display: none;
  }
}

.app-page-navigation__item::before {
  content: "\2014 "; // "— "
  margin-left: -(govuk-spacing(4));
  padding-right: govuk-spacing(1);
}
