.app-image-card {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: govuk-spacing(7);
}

.app-image-card--large {
  @include govuk-media-query($from: desktop) {
    flex-wrap: nowrap;

    .app-image-card__image-wrapper {
      max-width: 50%;
      margin-right: govuk-spacing(4);
    }
  }
}

.app-image-card__image-wrapper {
  width: 100%;
  margin: 0;
  @include govuk-responsive-margin(2, "bottom");
}

.app-image-card__text {
  *:last-child {
    margin-bottom: 0;
  }
}
